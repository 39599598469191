<template>
  <div class="container">
    <Info :name="info.name" :post="info.postName" :status="status"></Info>
    <div class="box">
      <p class="txt">职位办理进度</p>
      <van-steps direction="vertical" class="step" :active="active">
        <van-step>
          <h3 class="title">提交面试登记表</h3>
<!--          <p class="time">2016-07-12 12:40</p>-->
        </van-step>
        <van-step>
          <h3 class="title">面试中</h3>
<!--          <p class="time">2016-07-12 12:40</p>-->
        </van-step>
        <van-step>
          <h3 class="title">{{info.re_empstatus == 10 ? '面试失败' : '面试成功'}}</h3>
          <!--          <p class="time">2016-07-12 12:40</p>-->
        </van-step>
        <van-step>
          <h3 class="title">提交入职登记表</h3>
<!--          <p class="time">2016-07-12 12:40</p>-->
        </van-step>
        <van-step>
          <h3 class="title">入职审批</h3>
<!--          <p class="time">2016-07-12 12:40</p>-->
        </van-step>
        <van-step>
          <h3 class="title">已入职</h3>
<!--          <p class="time">2016-07-12 12:40</p>-->
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import { GetUserStatus } from "@/api/base";
import Info from '../components/PersonalInfo'

export default {
  name: "SearchProgress",
  components: {
    Info
  },
  created() {
    this.$store.dispatch('changeLocation', '进度查询')
    this.fetchData()
  },
  data(){
    return {
      active: -1,
      info: {
        postName: ''
      },
      status: false
    }
  },
  methods: {
    fetchData(){
      GetUserStatus({openId: this.$store.state.info.openId}).then(res=> {
        // res = {
        //   errcode: 200,
        //   message: null,
        //   row: {name: "李关亮", postName: "客服专员", re_empstatus: 2},
        // }
        // res= {
        //   errcode: 2,
        //   message: "无面试信息数据",
        //   row: null
        // }
        if(res.row == null && res.errcode == 2){
          this.$toast(res.message)
          this.info.postName = res.message;
          return
        }
        this.info = res.row;
        // alert(res.row.re_empstatus)
        switch (res.row.re_empstatus) {
          // case -1:break;                              //-1: 无面试信息数据,
          case 0: this.active = 0; break;                //0: 录入面试信息；
          case 1:this.active = 2;break;                  //1：面试成功；
          case 2:this.active = 3;break;                  //2：提交入职登记表；
          case 3:this.active = 4;break;                  //3入职办理中（弃用）；
          case 4:this.active = 5;this.status= true;break;//4: 录用（录用时分配账号对接钉钉）
          case 10:this.active = 2;break;                 //10：面试失败；
          case 11:this.active = 1;break;                 //11:面试中
          case 20:break;                                 //20：放弃入职；
          case 30:break;                                 //30：入职办理失败；
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  padding-top: 2px;
  .box{
    box-sizing: border-box;
    padding-left: 15px;
    .txt{
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: normal;
      color: #333333;
      line-height: 17px;
      padding: 25px 0 13px;
    }
    .step{
      .title{
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: normal;
        color: #333333;
        line-height: 15px;
        margin: 0;
      }
      .time{
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        line-height: 28px;
      }
    }
  }
}
</style>
<style>
.van-step--vertical .van-step__circle-container{
  font-size: 18px;
}
.van-step__circle{
  width: 6px;
  height: 6px;
}
.van-step--vertical{
  padding: 10px 10px 25px 0;
}
.van-step--vertical:not(:last-child)::after{
  border-bottom-width: 0;
}
</style>
